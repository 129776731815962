import request from "../utils/request";
export function getStudentApplications(student_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentApplications',
        student_id
    })
}
export function getStudentApplicationsV1(student_id, project_code) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentApplications',
        student_id,
        project_code
    })
}
export function getMyMessages(id, project_code) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getMyMessages',
        id,
        project_code
    })
}
export function sendMessage(student_id, type, sender_type, content, project_code, template_name) {
    return request.post('./WAO_Resource/index.php', {
        function: 'sendMessage',
        student_id,
        type,
        sender_type,
        content,
        project_code,
        template_name
    })
}
export function getStudentAwardsV1(project_code, student_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentAwardsV1',
        project_code,
        student_id
    })
}

export function getStudentTranscripts(project, student_id) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getStudentTranscripts',
        project,
        student_id
    })
}

export function getProjectByCode(project_code) {
    return request.post('./WAO_Resource/index.php', {
        function: 'getProjectByCode',
        project_code
    })
}