import request from "../utils/request";

export function getTestVisibleChangeCount(template_id, student_id, project_code) {
    return request.post('/Test_Server/index.php', {
        function: 'getTestVisibleChangeCount',
        template_id,
        student_id,
        project_code
    })
}

export function uploadTestHeartBeat(student_id, template_id, status) {
    return request.post('/Test_Server/index.php', {
        function: 'uploadTestHeartBeat',
        student_id,
        template_id,
        status
    })
}


export function uploadExamShotNew(param) {
    return request.post('/Test_Server/index.php',
        param
    )
}

export function changeVisibleLog(student_id, project_code, template_id) {
    return request.post('/Test_Server/index.php', {
        function: 'changeVisibleLog',
        student_id,
        project_code,
        template_id
    })
}

export function initExamShotTimePoint(student_id, project_code, template_id) {
    return request.post('/Test_Server/index.php', {
        function: 'initExamShotTimePoint',
        student_id,
        project_code,
        template_id
    })
}