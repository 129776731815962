<template>
    <el-card v-loading="loadingPdf" style="position: relative">
      <div v-if="loadingResult ==0" style="cursor: none;width: 100%;height: 100%;">
        <div v-for="page in singlePdfPages" style="width: 100%;height: 100%" :key="page">
          <!-- <p class="show-big-pic" @click="showBigPic(page)">Click for larger image</p> -->
          <canvas :id="'pdfCanvas' + page"/>
        </div>
      </div>
      <div v-if="loadingResult ==1"
           style="width: 100%;height: 100%;min-height:300px;background-color:transparent;cursor: pointer"
           @click="reloadPdf">
        <div style="color: #072d76;font-size: 20px;font-family: Montserrat-SemiBold;margin-top: 200px">
          Material failed to load. Please click on the "Reload" button below to try again.<span class="dot">...</span>
        </div>
  
      </div>
  
      <el-dialog :visible.sync="showBigPicDialog" :modal="true" :show-close="false"
                 :modal-append-to-body="true"
                 custom-class="showCustomPicDialogStyle">
  
        <div>
          <el-image :src="bigPicPath"></el-image>
        </div>
  
      </el-dialog>
  
    </el-card>
  </template>
  <script>
  
  import {Item, List} from 'linked-list'
  
  const PDFJS = require('pdfjs-dist')
  // PDFJS.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.5.207/pdf.worker.js'
  PDFJS.GlobalWorkerOptions.workerSrc = 'https://mainbucket.learningfirst.cn/remotePdf.js'
  
  
  class PdfItem extends Item {
    constructor(hasLoaded, pdf, pdfPages, url, index) {
      super()
      this.hasLoaded = hasLoaded
      this.pdf = pdf
      this.pdfPages = pdfPages
      this.url = url
      this.index = index
    }
  }
  
  export default {
    name: "PDF",
    props: {
      pdfCallback: {
        type: Function,
        default: null
      },
      parentShowBigPic: {
        type: Number,
        default: 0,
      },
      parentReloadPic: {
        type: Number,
        default: 0,
      },
    },
    watch: {
      parentShowBigPic: {
        handler(newVal, oldVal) {
          if (newVal != 0){
            this.showBigPic(1)
          }
        },
        immediate: true
      },
  
      parentReloadPic: {
        handler(newVal, oldVal) {
          if (newVal != 0){
            this.reloadPdf()
          }
        },
        immediate: true
      },
  
    },
    data() {
      return {
        loadingPdf: false,
        //测试使用的
        fileUrl: 'https://mainbucket.learningfirst.cn/FBLA%E7%BB%8F%E6%B5%8E%E5%AD%A6%E7%BA%BF%E4%B8%8A%E8%80%83%E8%AF%95%E8%AE%BE%E5%A4%87%E8%A6%81%E6%B1%82.pdf',
        pdfWidth: '', // 宽度
        pdfScale: 1.5, // 放大倍数
        pdfCurrentPageIndex: 0,
        globalLoading: undefined,
        pdfDocLink: new List(),
        pdfTotalSize: 0,
        singlePdfPages: 0,
        loadingResult: 0,
        showBigPicDialog: false,
        bigPicPath: ''
      }
    },
    methods: {
      showBigPic(page) {
        let canvas = document.getElementById("pdfCanvas" + page);
        this.bigPicPath = canvas.toDataURL("image/png");
        this.showBigPicDialog = true
      },
  
      initPdfView(pdf_urls) {
        //填充链表
        this.pdfTotalSize = pdf_urls.length
        for (var i = 0; i < this.pdfTotalSize; i++) {
          let url = pdf_urls[i]
          let item = new PdfItem(false, null, 0, url, i)
          this.pdfDocLink.append(item)
        }
        this.notifyParent()
        this.nextPage()
      },
  
      renderPdf() {
        this.clearCache()
        this.singlePdfPages = []
        this.loadingPdf = true
        this.loadingResult = 0
        let pdfItem = this.pdfDocLink.toArray()[this.pdfCurrentPageIndex - 1]
        if (!pdfItem.hasLoaded) {
          //网络请求，然后存放缓存里面
          console.log('缓存里面没有获取到PDF 信息，直接下载')
          this.parsePdf(pdfItem.url)
        } else {
          //直接渲染
          this.singlePdfPages = pdfItem.pdfPages
          console.log('从缓存里面去加载PDF信息')
          this.renderPage(pdfItem.pdf, pdfItem.url, pdfItem.pdfPages, 1)
        }
      },
      parsePdf(pdf_url) {
        let that = this
        let loadingTask = PDFJS.getDocument(pdf_url)
        loadingTask.promise.then(pdf => {
          let numbers = pdf.numPages
          let pdfDoc = pdf
          that.singlePdfPages = pdf.numPages
          var index = that.pdfCurrentPageIndex
          index--
          that.pdfDocLink.toArray()[index].pdf = pdfDoc
          that.pdfDocLink.toArray()[index].pdfPages = numbers
          that.pdfDocLink.toArray()[index].hasLoaded = true
          that.$nextTick(() => {
            that.renderPage(pdfDoc, pdf_url, numbers, 1)
          })
        }).catch((err) => {
          this.loadingPdf = false
          this.loadingResult = 1
          this.$message.error({message: "PDF loading failed "})
        })
      },
      renderPage(pdfDoc, pdf_url, pdfPages, num) {
        const that = this
        try {
          pdfDoc.getPage(num).then(page => {
            const canvas = document.getElementById('pdfCanvas' + num)
            const ctx = canvas.getContext('2d')
            const dpr = window.devicePixelRatio || 1
            const bsr =
                ctx.webkitBackingStorePixelRatio ||
                ctx.mozBackingStorePixelRatio ||
                ctx.msBackingStorePixelRatio ||
                ctx.oBackingStorePixelRatio ||
                ctx.backingStorePixelRatio ||
                1
            const ratio = dpr / bsr
            const viewport = page.getViewport({scale: this.pdfScale})
  
            canvas.width = viewport.width * ratio
            canvas.height = viewport.height * ratio
  
            canvas.style.width = '100%'
            // canvas.style.fontSize = '1000px'
  
            that.pdfWidth = '100%'
  
            canvas.style.height = '100%'
  
            ctx.setTransform(ratio, 0, 0, ratio, 0, 0)
            // 将 PDF 页面渲染到 canvas 上下文中
            const renderContext = {
              canvasContext: ctx,
              viewport: viewport
            }
            page.render(renderContext)
            if (pdfPages > num) {
              this.renderPage(pdfDoc, pdf_url, pdfPages, num + 1)
            } else {
              this.loadingResult = 0
              this.loadingPdf = false
            }
          })
        } catch (e) {
          this.loadingPdf = false
          this.loadingResult = 1
          this.$message.error({message: "PDF loading failed "})
        }
      },
      prePage() {
        this.pdfCurrentPageIndex--
        if (this.pdfCurrentPageIndex <= 0) {
          this.pdfCurrentPageIndex++
          this.$message.error({message: "This is the first page"})
          return this.pdfCurrentPageIndex
        }
        this.renderPdf()
        return this.pdfCurrentPageIndex
      },
      nextPage() {
  
        if ( this.pdfTotalSize == 0){
          return
        }
        this.pdfCurrentPageIndex++
        if (this.pdfCurrentPageIndex > this.pdfTotalSize) {
          this.pdfCurrentPageIndex--
          this.$message.error({message: "You have reached the last page"})
          return this.pdfCurrentPageIndex
        }
        this.renderPdf()
        return this.pdfCurrentPageIndex
      },
      reloadPdf() {
        console.log(11111)
        this.loadingResult = 0
        this.gotoTargetPage(this.pdfCurrentPageIndex)
      },
      gotoTargetPage(index) {
        this.pdfCurrentPageIndex = index
        this.renderPdf()
        return this.pdfCurrentPageIndex
      },
      checkIsLoadingPdf() {
        if (this.loadingPdf) {
          this.$message.error({message: "loading..."})
          return true
        }
        return false
      },
      notifyParent() {
        this.$emit('pdfCallback', this.pdfTotalSize);
      },
      clearCache() {
        for (let i = 0; i < this.singlePdfPages; i++) {
          let canvas = document.getElementById('pdfCanvas' + (i + 1))
          if (canvas) {
            try {
              const ctx = canvas.getContext('2d')
              ctx.clearRect(0, 0, canvas.width, canvas.height);
            } catch (e) {
            }
          }
        }
      }
    }
  }
  </script>
  
  <style>
  .showCustomPicDialogStyle {
    width: 80%;
    height: 90vh;
    overflow-x: auto;
    overflow-y: auto;
    text-align: center;
    background-color: white;
    padding: 0px;
    box-shadow: 0px 0px 0px 0px transparent;
  }
  </style>
  
  <style scoped>
  
  .show-big-pic {
    display: inline-block;
    box-sizing: border-box;
    cursor: pointer;
    padding: 6px 10px;
    color: #2d50b0;
    font-size: 18px;
    border-radius: 6px;
    border-color: #2d50b0 ;
    border-width: 1px;
    border-style: solid;
  }
  
  
  </style>
  